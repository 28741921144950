import styled from 'styled-components'
import arrowIcon from '../assets/images/arrow.svg'

export default function InsiteBadge() {
  return (
    <>
      <Badge href="https://www.presentwithinsite.com/" target="_blank">
        Presented with Insite
        <NewWindow src={arrowIcon} alt={'arrow'} />
      </Badge>
    </>
  )
}

const Badge = styled.a`
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  background: #f0f0f0;
  border-radius: 16px;
  color: #4e4adb;
  width: fit-content;
  margin: auto;
  display: flex;
  text-decoration: none;

  :hover {
    background: white;
    cursor: pointer;
  }
`

const NewWindow = styled.img`
  background-color: #4e4adb;
  border-radius: 24px;
  color: white;
  height: 8px;
  width: 8px;
  padding: 4px;
`
