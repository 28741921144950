import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/ibm-plex-sans';
import { spacing } from '../../constants';
import { Icon } from '../Icon/Icon';
export function Button({ iconUrl, children }) {
    const [buttonActive, setButtonActive] = useState(false);
    // TODO: Inverting the button icon colour on touch events is not working great, we might need to handle SVGs differently
    return (React.createElement(StyledButton, { onTouchStartCapture: () => setButtonActive(true), onTouchEndCapture: () => setButtonActive(false), onMouseEnter: () => setButtonActive(true), onMouseLeave: () => setButtonActive(false) },
        iconUrl ? React.createElement(Icon, { iconUrl: iconUrl, bright: buttonActive }) : null,
        children));
}
// TODO: Maybe rework this with a <label> to split up some of the styling, and improve for screen readers
const StyledButton = styled.button `
  font-family: 'IBM Plex Sans';
  display: inline-flex;
  align-items: center;
  column-gap: ${spacing.double};
  border: 0;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #b3b3b3;
  background-color: #fff;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding: 4px 12px;
  user-select: none;

  :active {
    color: #fff;
    background-color: #4e4adb;
    border-color: #4e4adb;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }

  @media (hover: hover) {
    :hover {
      color: #fff;
      background-color: #4e4adb;
      border-color: #4e4adb;
      box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.05);
      cursor: pointer;
    }
  }
`;
