import React from 'react'
import { Modal, Button, Typography, Box, Stack } from '@mui/material'
import { ProjectConfig } from '../types/ProjectConfig'
import { Info, PinDrop } from '@mui/icons-material'

interface IntroModalProps {
  project: ProjectConfig
}

export default function IntroModal({ project }: IntroModalProps) {
  const [open, setOpen] = React.useState(
    project.starterContent.modalVisible ?? false
  )
  const handleClose = () => {
    setOpen(false)
  }
  const hasModalSummary = project.starterContent.modalSummary
  return (
    <Modal open={open} onClose={handleClose} disableAutoFocus>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '18px',
          borderRadius: '12px',
          boxShadow: 24,
          border: '1px solid rgba(0, 0, 0, 0.12)',
          minWidth: '260px',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2 }}>
          Welcome to {project.info.name}
        </Typography>
        <Stack spacing={1} mb={2}>
          <Stack direction="row" spacing={2}>
            <PinDrop sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
            <Typography variant="body1">
              {project.starterContent.location}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Info sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
            <Typography variant="body1">
              {project.starterContent.type}
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {hasModalSummary
            ? project.starterContent.modalSummary
            : project.starterContent.description}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleClose}
        >
          Enter
        </Button>
      </Box>
    </Modal>
  )
}
