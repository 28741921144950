import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../constants';
const StyledList = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid #e6e6e6;
  border-radius: 8px;
`;
const ListEntry = styled.div `
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  column-gap: ${spacing.double};
  padding: ${spacing.triple};
`;
const Divider = styled.hr `
  border: none;
  border-top: 2px dashed #e6e6e6;
  margin: 0;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
`;
export function List({ children }) {
    return (React.createElement(StyledList, null, React.Children.map(children, (child, index) => index === 0 ? (React.createElement(ListEntry, null, child)) : (React.createElement(React.Fragment, null,
        React.createElement(Divider, null),
        React.createElement(ListEntry, null, child))))));
}
