import React from 'react'
import '@fontsource/ibm-plex-sans'
import { ProjectConfig } from '../types/ProjectConfig'
import { Stack, Typography } from '@mui/material'
import { Info, PinDrop } from '@mui/icons-material'

export type ProjectSummaryProps = {
  project: ProjectConfig
}

export default function ProjectSummary({ project }: ProjectSummaryProps) {
  return (
    <>
      <Typography variant="h1" mb={4}>
        {project.info.name}
      </Typography>
      <Stack spacing={1} mb={4}>
        <Typography variant="body2">Project Details</Typography>
        <Stack direction="row" spacing={2}>
          <PinDrop sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
          <Typography variant="body1">
            {project.starterContent.location}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Info sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
          <Typography variant="body1">{project.starterContent.type}</Typography>
        </Stack>
      </Stack>
      <Stack spacing={1} mb={4}>
        <Typography variant="body2">Project Overview</Typography>
        <Typography variant="body1">
          {project.starterContent.description}
        </Typography>
      </Stack>
      {project.starterContent.feedbackEmail &&
      project.starterContent.feedbackDescription ? (
        <Stack spacing={1} mb={4}>
          <Typography variant="body2">Community Feedback</Typography>
          <Typography variant="body1">
            {project.starterContent.feedbackDescription}
            <a href={`mailto:${project.starterContent.feedbackEmail}`}>
              {project.starterContent.feedbackEmail}
            </a>
          </Typography>
        </Stack>
      ) : null}
      {project.starterContent.customContent?.map((content, index) => (
        <Stack spacing={1} mb={4} key={index}>
          <Typography variant="body2">{content.header}</Typography>
          <Typography variant="body1">{content.body}</Typography>
        </Stack>
      ))}
    </>
  )
}
