const standardSpacing = 4;
export const spacing = {
    single: `${standardSpacing}px`,
    double: `${standardSpacing * 2}px`,
    triple: `${standardSpacing * 3}px`,
    quadruple: `${standardSpacing * 4}px`,
    quintuple: `${standardSpacing * 5}px`,
};
export const size = {
    single: `${standardSpacing}px`,
    double: `${standardSpacing * 2}px`,
    triple: `${standardSpacing * 3}px`,
    quadruple: `${standardSpacing * 4}px`,
    quintuple: `${standardSpacing * 5}px`,
};
