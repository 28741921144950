import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import Map, {
  useMap,
  ScaleControl,
  NavigationControl,
  AttributionControl,
} from 'react-map-gl'
import styled from 'styled-components'

import 'mapbox-gl/dist/mapbox-gl.css'
import useWindowDimensions from '../hooks/useWindowDimensions'
import Controls from './Controls'
import { LayerGroup, MapLayer } from '../types/LayerGroup'
import { isLayerVisible } from '../helpers/mapbox.helpers'
import ResponsiveLayout from './ResponsiveLayout'
import { WindowOrientation } from '../types/WindowOrientation'
import { Layer, Style } from 'mapbox-gl'
import { ProjectConfig } from '../types/ProjectConfig'
import { MapLegend } from './MapLegend/MapLegend'

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
import mapboxgl from 'mapbox-gl'
import { Toggle3DButton } from './Toggle3DButton'
// @ts-ignore
// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

// Set your mapbox token here
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

type LayoutDimensions = {
  sidebar: {
    width: number
    height: number
  }
  map: {
    width: number
    height: number
  }
}

type MapViewProps = {
  project: ProjectConfig
}

export default function MapView({ project }: MapViewProps) {
  const { mymap } = useMap()

  const {
    height: windowHeight,
    width: windowWidth,
    orientation: windowOrientation,
  } = useWindowDimensions()

  const mapRatio = useMemo(() => {
    switch (windowOrientation) {
      case WindowOrientation.landscape:
        return 0.7
      case WindowOrientation.portrait:
      default:
        return 0.55
    }
  }, [windowOrientation])

  const layoutDimensions = useMemo<LayoutDimensions>(() => {
    if (windowWidth >= windowHeight) {
      // Landscape orientation
      const mapWidth = windowWidth * mapRatio
      const sidebarWidth = windowWidth - mapWidth
      return {
        sidebar: { width: sidebarWidth, height: windowHeight },
        map: { width: mapWidth, height: windowHeight },
      }
    } else {
      // Portrait orientation
      const mapHeight = windowHeight * mapRatio
      const sidebarHeight = windowHeight - mapHeight
      return {
        sidebar: { width: windowWidth, height: sidebarHeight },
        map: { width: windowWidth, height: mapHeight },
      }
    }
  }, [windowWidth, windowHeight, mapRatio])

  useEffect(() => {
    if (mymap && project.mapViewSettings.focusArea) {
      const sw = project.mapViewSettings.focusArea?.southWestCorner
      const ne = project.mapViewSettings.focusArea?.northEastCorner
      if (sw && ne) {
        mymap.fitBounds([
          [sw.longitude, sw.latitude],
          [ne.longitude, ne.latitude],
        ])
      }
    }
  }, [layoutDimensions, mymap, project.mapViewSettings.focusArea])

  const [mapStyle, setMapStyle] = useState<Style | undefined>(undefined)
  const [mapGroups, setMapGroups] = useState<LayerGroup[]>([])

  const toggleLayerVisibility = (layerId: string) => {
    if (!mapStyle) {
      return
    }
    mapStyle.layers.forEach((currentLayer) => {
      if (currentLayer.id === layerId) {
        const layer = currentLayer as Layer
        const isVisible = isLayerVisible(layer)

        const newVisibility = isVisible ? 'none' : 'visible'

        if (layer.layout) {
          layer.layout.visibility = newVisibility
        } else {
          layer.layout = { visibility: newVisibility }
        }
        setMapLayerVisibility(layer.id, !isVisible)
      }
    })
    // Update mapStyle state to trigger re-render
    setMapStyle({ ...mapStyle })
  }

  const setMapLayerVisibility = (layerId: string, isVisible: boolean) => {
    mapGroups.forEach((group) => {
      group.layers.forEach((layer) => {
        if (layer.id === layerId) {
          layer.visible = isVisible
        }
      })
    })
    setMapGroups([...mapGroups])
  }

  const handleStyleDataLoaded = () => {
    // Parse map groups on initial map data load
    if (!mymap || !mymap?.isStyleLoaded() || !(mapGroups.length === 0)) {
      return
    }

    const groups = mymap.getStyle().metadata['mapbox:groups']
    const groupKeys = Object.keys(groups)

    const openGroupKeys = groupKeys.filter(
      (groupKey) => groups[groupKey]['collapsed'] === false
    )

    const openGroups = openGroupKeys.map<LayerGroup>((openGroupKey) => {
      const groupLayers = mymap
        .getStyle()
        .layers.filter((currentLayer) => {
          const layer = currentLayer as Layer
          const layerGroupId = layer.metadata['mapbox:group']
          return openGroupKey === layerGroupId
        })
        .map<MapLayer>((currentLayer) => {
          const layer = currentLayer as Layer
          return {
            id: layer.id,
            visible: isLayerVisible(layer),
          }
        })

      return {
        id: openGroupKey,
        name: groups[openGroupKey]['name'],
        collapsed: groups[openGroupKey]['collapsed'],
        layers: groupLayers,
      }
    })

    setMapGroups(openGroups)
    if (!mapStyle) {
      setMapStyle(mymap.getStyle())
    }
  }

  return (
    <ResponsiveLayout orientation={windowOrientation}>
      <SidebarContainer
        width={layoutDimensions.sidebar.width}
        height={layoutDimensions.sidebar.height}
      >
        <Controls
          project={project}
          mapGroups={mapGroups}
          toggleLayerVisibility={toggleLayerVisibility}
        />
      </SidebarContainer>
      <Map
        id="mymap"
        initialViewState={{
          longitude: project.mapViewSettings.initialCoordinates?.longitude,
          latitude: project.mapViewSettings.initialCoordinates?.latitude,
          zoom: project.mapViewSettings.initialZoom,
        }}
        {...project.mapViewSettings}
        style={{
          width: layoutDimensions.map.width,
          height: layoutDimensions.map.height,
        }}
        mapStyle={mapStyle ? mapStyle : project.mapboxSettings.mapStyle}
        styleDiffing
        mapboxAccessToken={MAPBOX_TOKEN}
        onData={(event) => handleStyleDataLoaded()}
        reuseMaps
        attributionControl={false}
      >
        <MapLegend />
        <NavigationControl />
        <Toggle3DButton map={mymap} />
        <AttributionControl compact position="top-left" />
        <ScaleControl unit={project.mapViewSettings.initialUnitSystem} />
      </Map>
    </ResponsiveLayout>
  )
}

type SidebarContainerProps = {
  width: number
  height: number
}
const SidebarContainer = styled.div<SidebarContainerProps>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  overflow: auto;
  background-color: #fafafa;
`
