import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../constants';
const StyledRow = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${spacing.quadruple};
`;
export function Row({ children }) {
    return React.createElement(StyledRow, null, children);
}
