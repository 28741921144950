import styled from 'styled-components'
import { MapLegendEntry } from '../../types/MapLegendEntry'
import LegendLabel from './LegendLabel'

type LegendItemProps = {
  legendEntry: MapLegendEntry
}

export default function LegendItem({ legendEntry }: LegendItemProps) {
  return (
    <Container>
      <Mark color={legendEntry.color} />
      <LegendLabel>{legendEntry.name}</LegendLabel>
    </Container>
  )
}

const Container = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.5px;
`

type MarkProps = {
  color: string
}
const Mark = styled.div<MarkProps>`
  width: 8px;
  height: 8px;
  border-radius: 24px;
  margin-right: 8px;
  border: solid 1px lightgray;
  background-color: ${(props) => props.color};
`
