import React from 'react';
import styled, { css } from 'styled-components';
import { size } from '../../constants';
const IconImage = styled.img `
  height: ${size.quadruple};

  ${(props) => props.bright &&
    css `
      filter: brightness(200%);
    `};
`;
export function Icon({ iconUrl, bright }) {
    return React.createElement(IconImage, { src: iconUrl, bright: bright });
}
