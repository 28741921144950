import styled from 'styled-components'
import { WindowOrientation } from '../types/WindowOrientation'

type ResponsiveLayoutProps = {
  children: React.ReactNode[]
  orientation: WindowOrientation
}

export default function ResponsiveLayout({
  children,
  orientation,
}: ResponsiveLayoutProps) {
  switch (orientation) {
    case WindowOrientation.portrait:
      return <PortraitLayout>{children}</PortraitLayout>
    case WindowOrientation.landscape:
    default:
      return <LandscapeLayout>{children}</LandscapeLayout>
  }
}

const LandscapeLayout = styled.div`
  display: flex;
  flex-direction: row;
`

const PortraitLayout = styled.div`
  display: flex;
  flex-direction: column-reverse;
`
