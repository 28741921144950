import styled from 'styled-components'

const Spinner = styled.div`
  border: 16px solid #dee5f2;
  border-top: 16px #4e4adb solid;

  border-radius: 50%;
  height: 120px;
  width: 120px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

export default Spinner
