import { Layer } from 'mapbox-gl'

export function isLayerVisible(layer: Layer) {
  // The layer is hidden if the layout.visibility property is set to "none"
  return layer.layout
    ? layer.layout.visibility
      ? layer.layout.visibility !== 'none'
      : true
    : true
}
