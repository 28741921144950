import styled from 'styled-components'

const LegendLabel = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #1a1a1a;
  font-family: 'IBM Plex Sans';
  letter-spacing: 0.5px;
`

export default LegendLabel
