import React from 'react';
import styled from 'styled-components';
import '@fontsource/ibm-plex-sans';
export function Text({ children, variant }) {
    switch (variant) {
        case 'title':
            return React.createElement(Title, null, children);
        case 'header':
            return React.createElement(Header, null, children);
        case 'contentheader':
            return React.createElement(ContentHeader, null, children);
        case 'body':
            return React.createElement(Body, null, children);
        case 'bodyitalic':
            return React.createElement(BodyItalic, null, children);
        case 'label':
            return React.createElement(Label, null, children);
        case 'labelbold':
            return React.createElement(LabelBold, null, children);
        default:
            return React.createElement(Body, null, children);
    }
}
// TODO: Would like to capture common attributes in a common base component like below.
//       There seems to be an issue with this and our current combination of styled-components
//       and typescript versions
// ---
// const TextCommon = styled.div`
//   font-family: 'IBM Plex Sans';
//   font-style: normal;
// `
// const Title = styled(TextCommon)`
//   font-weight: 600;
//   font-size: 24px;
//   line-height: 32px;
// `
// ---
const Title = styled.div `
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 58px;
  color: #4e4adb;
`;
const Header = styled.div `
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
const ContentHeader = styled.div `
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
const Body = styled.div `
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
const BodyItalic = styled.div `
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
const Label = styled.div `
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;
const LabelBold = styled.div `
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;
