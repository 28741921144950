import styled from 'styled-components'

type IconProps = {
  src: string
  alt?: string
  onClick?: () => void
}

export default function Icon({ src, alt, onClick }: IconProps) {
  return (
    <Container onClick={onClick}>
      <Image src={src} alt={alt} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #e6e6e6;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  cursor: pointer;

  :hover {
    background: #d7d7d7;
  }
`
const Image = styled.img`
  height: 12px;
`
