import { useState } from 'react'
import { MapRef } from 'react-map-gl'
import styled from 'styled-components'

type Toggle3DButtonProps = {
  map: MapRef | undefined
}

export function Toggle3DButton({ map }: Toggle3DButtonProps) {
  const [viewModeLabel, setViewModeLabel] = useState('3D')

  if (!map) {
    return null
  }

  function is3DView() {
    return map?.getPitch() !== 0
  }

  // update button label on pitch change events on the map object
  map.on('pitch', () => {
    if (is3DView()) {
      setViewModeLabel('2D')
    } else {
      setViewModeLabel('3D')
    }
  })

  const toggleViewMode = () => {
    // TODO: move to project config
    const buildingAssetsVisibleZoom = 14

    // zoom in only if zoomed out and going from 2D to 3D
    if (is3DView()) {
      const pitch2D = 0
      map.easeTo({ pitch: pitch2D, duration: 500 })
    } else {
      const pitch3D = 45
      const targetZoom = Math.max(map.getZoom(), buildingAssetsVisibleZoom)
      map.easeTo({ pitch: pitch3D, duration: 500, zoom: targetZoom })
    }
  }

  return <Button onClick={toggleViewMode}>{viewModeLabel}</Button>
}

const Button = styled.button`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  color: #1a1a1a;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 106px;
  right: 9px;
  z-index: 1;
  border: 2px solid #ddd;

  :hover {
    background-color: #f5f5f5;
  }
`
