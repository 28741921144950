import styled from 'styled-components'

const LegendButton = styled.button`
  position: absolute;
  bottom: 12px;
  right: 12px;
  background-color: white;
  border-radius: 24px;
  border: 2px solid lightgray;
  padding: 4px 8px;
  cursor: pointer;

  :hover {
    background-color: #fafafa;
    border-color: gray;
  }
`

export default LegendButton
