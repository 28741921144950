import { MapProvider } from 'react-map-gl'
import { LoadingState, useProjectConfig } from '../hooks/useProjectConfig'
import { NotFoundError } from '../pages/NotFoundError'
import LoadingPage from '../pages/LoadingPage'
import MapView from './MapView'
import IntroModal from './IntroModal'

export default function Project() {
  const project = useProjectConfig()

  // TODO: Not loving how this code turned out, needs to get sorted in a better way. Maybe using Suspense?
  if (project === LoadingState.loading) {
    return <LoadingPage />
  } else if (project === LoadingState.error) {
    return <NotFoundError />
  } else {
    if (project.info.name) {
      document.title = project.info.name
    }
    return (
      <>
        <MapProvider>
          <MapView project={project} />
        </MapProvider>
        <IntroModal project={project} />
      </>
    )
  }
}
