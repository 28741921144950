import { ThemeProvider } from '@mui/material/styles'
import Project from './components/Project'
import theme from './constants/theme'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Project />
    </ThemeProvider>
  )
}

export default App
