import { Button } from '@mui/material'

type ToggleButtonProps = {
  isSelected: boolean
  label: string
  onClick: () => void
}

export default function ToggleButton({
  isSelected,
  label,
  onClick,
}: ToggleButtonProps) {
  return (
    <Button
      onClick={onClick}
      fullWidth
      sx={{
        p: '0.5rem 0.75rem',
        borderRadius: '24px',
        textTransform: 'none',
        textAlign: 'left',
        justifyContent: 'flex-start',
        color: 'text.primary',
        border: '2px solid #e5e5e5',
        '&:hover': {
          border: '2px solid #b56943',
        },
        ...(isSelected && {
          backgroundColor: '#fffaf3',
          borderColor: '#b56943',
          '&:hover': {
            backgroundColor: '#fffaf3',
          },
        }),
      }}
    >
      {label}
    </Button>
  )
}
