import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { ProjectConfig } from '../types/ProjectConfig'

type FirebaseConfig = {
  apiKey: string | undefined
  authDomain: string | undefined
  projectId: string | undefined
  storageBucket: string | undefined
  messagingSenderId: string | undefined
  appId: string | undefined
  measurementId: string | undefined
}

export enum AnalyticsEventType {
  ToggleMapLayer = 'toggle_map_layer',
}

export function logAnalyticsEvent(
  eventType: AnalyticsEventType,
  project?: ProjectConfig,
  eventParams?: { [key: string]: any }
) {
  if (isFirebaseConfigured()) {
    const analytics = getAnalytics()
    const amendedEventParams = project
      ? {
          ...eventParams,
          project_name: project.id,
        }
      : eventParams
    logEvent(analytics, eventType, amendedEventParams)
  }
}

// Initialize Firebase
export function initFirebase() {
  const firebaseConfig = getFirebaseConfig()

  if (isFirebaseConfigured(firebaseConfig)) {
    const app = initializeApp(firebaseConfig)
    const analytics = getAnalytics(app)
    const db = getFirestore(app)
  } else {
    console.log('Firebase not configured')
  }
}

function getFirebaseConfig(): FirebaseConfig {
  return {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  }
}

function isFirebaseConfigured(firebaseConfig?: FirebaseConfig) {
  const config = firebaseConfig ?? getFirebaseConfig()
  return (
    config.apiKey &&
    config.authDomain &&
    config.projectId &&
    config.storageBucket &&
    config.messagingSenderId &&
    config.appId &&
    config.measurementId
  )
}
