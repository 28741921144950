import * as React from 'react'

import styled from 'styled-components'
import { LayerGroup } from '../types/LayerGroup'
import { ProjectConfig } from '../types/ProjectConfig'
import {
  AnalyticsEventType,
  logAnalyticsEvent,
} from '../helpers/firebase.helpers'
import ToggleButton from './ToggleButton'
import ProjectSummary from './ProjectSummary'
import InsiteBadge from './InsiteBadge'
import { Typography } from '@mui/material'

type ControlProps = {
  project?: ProjectConfig
  mapGroups: LayerGroup[]
  toggleLayerVisibility: (layerId: string) => void
}

export default function Controls({
  project,
  mapGroups,
  toggleLayerVisibility,
}: ControlProps) {
  const handleMapLayerToggleButtonClick = (layerId: string) => {
    toggleLayerVisibility(layerId)
    logAnalyticsEvent(AnalyticsEventType.ToggleMapLayer, project, {
      map_layer_id: layerId,
    })
  }

  return (
    <SidePanel>
      {project && <ProjectSummary project={project} />}
      <Column>
        {mapGroups.map((group) => (
          <Group key={group.id} className="input">
            <Typography variant="body2" mb={2}>
              {group.name}
            </Typography>
            <ReversedColumn>
              {group.layers.map((layer) => (
                <ToggleButton
                  key={layer.id}
                  isSelected={layer.visible}
                  onClick={() => handleMapLayerToggleButtonClick(layer.id)}
                  label={layer.id}
                />
              ))}
            </ReversedColumn>
          </Group>
        ))}
        <InsiteBadge />
      </Column>
    </SidePanel>
  )
}

const SidePanel = styled.div`
  padding: 24px;
  font-family: 'IBM Plex Sans';
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`

const ReversedColumn = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
`

const Group = styled.div``
