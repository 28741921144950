import { useState } from 'react'
import LegendButton from './LegendButton'
import LegendLabel from './LegendLabel'
import LegendPopover from './LegendPopover'

export function MapLegend() {
  const [showPopover, setShowPopover] = useState(false)

  const toggleLegendPopover = () => {
    setShowPopover(!showPopover)
  }

  return (
    <>
      <LegendButton onClick={toggleLegendPopover}>
        <LegendLabel>Legend</LegendLabel>
      </LegendButton>
      {showPopover ? <LegendPopover onClose={toggleLegendPopover} /> : null}
    </>
  )
}
