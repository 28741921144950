import {
  collection,
  CollectionReference,
  DocumentData,
  getDocs,
  getFirestore,
} from 'firebase/firestore'
import { ProjectConfig, ProjectConfigData } from '../types/ProjectConfig'

// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData>(collectionName: string) => {
  const firestore = getFirestore()
  return collection(firestore, collectionName) as CollectionReference<T>
}

export async function getProjectOverviews() {
  const querySnapshot = await getDocs(
    createCollection<ProjectConfigData>('projects')
  )
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() } as ProjectConfig
  })
}
