import styled from 'styled-components'
import { MapLegendEntry } from '../../types/MapLegendEntry'
import Icon from '../Common/Icon'
import List from '../Common/List'
import LegendItem from './LegendItem'
import closeIcon from '../../assets/images/close.svg'

// TODO: figure out how to link to layer colors using Mapbox API
// const waterColor = map.getPaintProperty('water', 'fill-color');
const mapLegendEntries: MapLegendEntry[] = [
  {
    name: 'Water',
    color: '#4eacfd',
  },
  {
    name: 'Greenspace',
    color: '#b1e085',
  },
  {
    name: 'Road',
    color: '#e9e9ed',
  },
  {
    name: 'Commercial',
    color: '#fcf4e8',
  },
  {
    name: 'Highway',
    color: '#fee59a',
  },
  {
    name: 'Building',
    color: '#ededed',
  },
]

type LegendPopoverProps = {
  onClose: () => void
}

export default function LegendPopover({ onClose }: LegendPopoverProps) {
  return (
    <Container>
      <List>
        {mapLegendEntries.map((entry, index) => (
          <LegendItem key={`map-legend-item${index}`} legendEntry={entry} />
        ))}
      </List>
      <Icon onClick={onClose} src={closeIcon} alt={'close'} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  border: 2px solid lightgray;
  position: absolute;
  bottom: 50px;
  right: 10px;
`
