import { Text } from '@collective-form/foundation-ui'
import styled from 'styled-components'

export function NotFoundError() {
  return (
    <PageContainer id="error-page">
      <Spacer />
      <Text variant="title">Looks like you've lost your way...</Text>
      <Spacer />
      <Text variant="header">It seems like nothing lives at this address.</Text>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`
const Spacer = styled.div`
  height: 20px;
`
