import { createTheme } from '@mui/material/styles'
import '@fontsource/ibm-plex-sans/400.css'
import '@fontsource/ibm-plex-sans/500.css'
import '@fontsource/ibm-plex-sans/600.css'
import '@fontsource/ibm-plex-sans/700.css'
import '@fontsource/ibm-plex-sans/400-italic.css'
import '@fontsource/ibm-plex-sans/500-italic.css'
import '@fontsource/ibm-plex-sans/600-italic.css'
import '@fontsource/ibm-plex-sans/700-italic.css'

const theme = createTheme({
  typography: {
    fontFamily: 'IBM Plex Sans',
    h1: {
      fontSize: '1.5rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.5)',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.5)',
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  shape: {
    borderRadius: 12,
  },
  spacing: 8,
  palette: {
    mode: 'light',
    contrastThreshold: 4.5,
    primary: {
      main: '#4e4adb',
    },
    secondary: {
      main: '#efefef',
    },
    background: {
      default: '#ffffff',
      paper: '#fafafa',
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
        },
      },
    },
  },
})

export default theme
