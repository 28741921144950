import styled from 'styled-components'
import Spinner from '../components/Spinner'

export default function LoadingPage() {
  return (
    <SpinnerContainer className="App">
      <Spinner />
    </SpinnerContainer>
  )
}

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`
